import { SquareShow } from "../DIFF/SquareShow";
import plus from "../Images/PLUS.png";
import bargraph from "../Images/graph2.png";
import linegraph from "../Images/GRAPH1.png";
import dots from "../Images/SITE_DIVIDER_DOTS.png";
import "../App.css";
import { useState } from "react";
import { DisplayRewards } from "../DIFF/DisplayRewards";
import { Milestone } from "../DIFF/Milestone";

export function Individual(props: any) {
  const [score, setScore] = useState(0);
  const [rank, setRank] = useState("");
  const [name, setName] = useState("");
  const [found, setFound] = useState(true);

  const [playerID, setPlayerID] = useState("");
  const [searchedID, setSearchedID] = useState("");
  const [rewards, setRewards] = useState<any>([]);
  const [level, setLevel] = useState<any>(0);

  function numberWithCommas(x: Number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function calcRewards(numberKills: any) {
    let levelRef = 0;
    const givenRewards: string[] = [];
    levelRef = Math.floor((10 * numberKills) / props.limits[0]);
    if (numberKills >= props.limits[0]) {
      givenRewards.push("1_individual_badge1.png");
      levelRef =
        Math.floor(
          (10 * (numberKills - props.limits[0])) /
            (props.limits[1] - props.limits[0])
        ) + 10;
      if (numberKills >= props.limits[1]) {
        givenRewards.push("2_individual_badge2.png");
        givenRewards.push("2_individual_pickapart.png");
        levelRef =
          Math.floor(
            (10 * (numberKills - props.limits[1])) /
              (props.limits[2] - props.limits[1])
          ) + 20;
        if (numberKills >= props.limits[2]) {
          givenRewards.push("3_individual_badge3.png");
          givenRewards.push("3_individual_spacenugs.png");
          levelRef =
            Math.floor(
              (10 * (numberKills - props.limits[2])) /
                (props.limits[3] - props.limits[2])
            ) + 30;
          if (numberKills >= props.limits[3]) {
            givenRewards.push("4_individual_badge4.png");
            givenRewards.push("4_individual_comicspecial.png");
            levelRef =
              Math.floor(
                (10 * (numberKills - props.limits[3])) /
                  (props.limits[4] - props.limits[3])
              ) + 40;
            if (numberKills >= props.limits[4]) {
              givenRewards.push("5_individual_badge5.png");
              givenRewards.push("5_individual_aeyebaton.png");
              levelRef = 50;
            }
          }
        }
      }
    } else {
      givenRewards.push("NO_REWARDS_UNLOCKED.png");
    }
    setLevel(levelRef);
    setRewards(givenRewards);
  }

  function findUser() {
    const isUser = (element: any) => element.AccountInfo.PlayFabId === playerID;
    const index = props.playersData.findIndex(isUser);
    if (index >= 0) {
      setFound(true);
      props.setIndividualData(props.playersData[index]);

      setSearchedID(playerID);
      const isGameState = (element: any) =>
        element.StatisticName === "A-EYE_Drones_Destroyed";
      const index_stat =
        props.playersData[index].PlayerStatistics.findIndex(isGameState);
      setName(props.playersData[index].AccountInfo.TitleInfo.DisplayName);
      let scores = 0;
      if (index_stat >= 0) {
        scores = Number(
          props.playersData[index].PlayerStatistics[index_stat].Value
        );
      }
      setScore(scores);
      calcRewards(scores);

      const isLeaderboardName = (element: any) =>
        element.name === "A-EYE_Drones_Destroyed";
      const index_leader =
        props.leadersData.leaderboards.findIndex(isLeaderboardName);
      const isPlayer = (element: any) =>
        element.PlayFabId === props.playersData[index].AccountInfo.PlayFabId;
      const index_rank =
        props.leadersData.leaderboards[index_leader].leaderboard.findIndex(
          isPlayer
        );

      if (index_rank > -1) {
        setRank(
          String(
            props.leadersData.leaderboards[index_leader].leaderboard[index_rank]
              .Position + 1
          )
        );
      } else {
        setRank("");
      }
    } else {
      setSearchedID("");
      setFound(false);
    }
  }

  function onClick() {
    props.onClick();
    props.setLink("/images/howtofindplayerID.png");
  }

  function detectEnter(e: { key: string }): any {
    if (e.key === "Enter") {
      findUser();
    }
  }

  function playerIDChange(input: any) {
    setPlayerID(input.target.value);
  }

  return (
    <>
      <div className="black new_block">
        <div className="padding_box_writing new_block">
          <div className="upperBox">
            <img src={linegraph} className="lineGraph" alt="logo" />
            <img src={bargraph} className="barGraph" alt="logo" />
            <img
              src={plus}
              style={{ height: "30px", position: "absolute", left: "0" }}
              alt="logo"
            />
            <div className="boxHeading">
              <p className="orange_text headingText">
                <span className="semibold">INDIVIDUAL</span> TRACKER
              </p>
              <SquareShow />
            </div>
          </div>

          <div className="upperBox" style={{ margin: "auto" }}>
            <div className="boxHeading container">
              <p className="left">
                <input
                  className="headingText textSmall"
                  placeholder="PLAYER ID..."
                  value={playerID}
                  onChange={playerIDChange}
                  onKeyDown={detectEnter}
                ></input>
              </p>
              <button className="right searchButton" onClick={findUser}>
                <p>SEARCH</p>
              </button>
            </div>
          </div>
          <div className="clickMore" style={{ padding: "3rem 0rem" }}>
            <button
              style={{ background: "none", border: "none" }}
              onClick={onClick}
            >
              <p className="orange_text">WHAT IS MY PLAYER ID?</p>
            </button>
          </div>
          {searchedID && (
            <>
              <div className="padding_box_writing new_block">
                <div className="upperBox">
                  <img
                    src={plus}
                    style={{ height: "30px", position: "absolute", left: "0" }}
                    alt="logo"
                  />
                  <div className="boxHeading">
                    <p style={{ paddingTop: "1rem" }}>
                      <span className="semibold" style={{ fontSize: "1.5rem" }}>
                        {name.toUpperCase()}
                      </span>
                    </p>
                    <p>
                      <span className="semibold" style={{ fontSize: "1rem" }}>
                        {searchedID}
                      </span>
                    </p>
                    <p className="headingText">
                      <span className="semibold">TOTAL </span>
                      <span className="orange_text">A-EYE</span>{" "}
                      <span className="semibold">DESTROYED</span> <br />
                      <span
                        className="orange_text"
                        style={{
                          fontSize: "5.5rem",
                        }}
                      >
                        {numberWithCommas(score)}
                      </span>
                      <br />
                      <span className="semibold" style={{ fontSize: "1.5rem" }}>
                        RANK:{" "}
                      </span>
                      <span style={{ fontSize: "1.5rem", fontWeight: 400 }}>
                        {rank}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <Milestone type={"individual"} level={level} />

              <div className="upperBox">
                <img
                  src={plus}
                  style={{ height: "30px", position: "absolute", left: "0" }}
                  alt="logo"
                />
                <div className="boxHeading">
                  <p className="orange_text headingText">REWARDS UNLOCKED</p>
                  <DisplayRewards rewards={rewards} />
                </div>
              </div>
            </>
          )}
          {!found && (
            <>
              <div className="padding_box_writing new_block">
                <div className="upperBox">
                  <img
                    src={plus}
                    style={{ height: "30px", position: "absolute", left: "0" }}
                    alt="logo"
                  />
                  <div className="boxHeading">
                    <p
                      className="bodyText"
                      style={{
                        fontWeight: "400",
                        paddingTop: "1rem",
                        fontSize: "1.75rem",
                      }}
                    >
                      Error: player not found. Check player ID is correct and/or
                      wait for next server update.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <img src={dots} className="bottom" alt="logo" />
      </div>
    </>
  );
}
