import droneCut from "../Images/Asset_3.png";
import dots from "../Images/SITE_DIVIDER_DOTS.png";
import plus from "../Images/PLUS.png";
import "../App.css";

export function PilotTime(props: any) {
  // const [showPopup, setShowPopup] = useState(false);

  function onClick() {
    props.onClick();
    props.setLink("/images/see_more.png");
  }

  return (
    <>
      <div className="grey new_block">
        <div className="upperBox">
          <img
            src={plus}
            style={{ height: "30px", position: "absolute", left: "0" }}
            alt="logo"
          />
          <div className="boxHeading">
            <p className="orange_text headingText">
              <span className="semibold">NOW IS YOUR TIME</span>, PILOT
            </p>
            <p className="bodyText">
              Attention, drone pilots! Red Rock is under siege by the relentless
              A-EYE forces. Aid Solomon by taking down their menacing drones.
              Destroy as many A-EYE drones as you can and just maybe we can
              uncover some of the secrets behind their attacks.
            </p>
          </div>
        </div>
        <div className="clickMore" style={{ padding: "1rem 0rem" }}>
          <button
            style={{ background: "none", border: "none" }}
            onClick={onClick}
          >
            <p className="orange_text">CLICK FOR MORE INFO</p>
          </button>
        </div>
        <img src={droneCut} className="bottom-right imagesNone" alt="logo" />
        <img src={dots} className="bottom" alt="logo" />
      </div>
    </>
  );
}
