import { useState } from "react";

export function ShowOneReward(props: any) {
  const [showSecond, setShowSecond] = useState(false);
  // const [startTime, setStartTime] = useState(Date.now());

  const showSecondImage = () => {
    if (props.secondImage !== "/milestone/hovers/NO_REWARDS_UNLOCKED.png") {
      console.log(props.secondImage);
      setShowSecond(true);
      // setStartTime(Date.now());
      console.log("Enter: " + Date.now());
    }
  };

  const hideSecondImage = () => {
    console.log("exit");
    setShowSecond(false);
  };

  return (
    <div
      onMouseOver={showSecondImage}
      onMouseOut={hideSecondImage}
      style={{
        // position: "relative",

        width: "calc(25% - 1rem)",
        padding: "0.5rem",
      }}
    >
      {showSecond && (
        <img
          src={props.secondImage}
          alt=""
          id="secondImage"
          style={{
            position: "absolute",
            left: "calc(50% - 20vw)",
            zIndex: 2,
            width: "40vw",
          }}
        />
      )}
      <img
        src={props.firstImage}
        alt=""
        style={{ width: "100%" }} // padding: "0.5rem" }
        id="firstImage"
      />
    </div>
  );
}
